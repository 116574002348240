/* @include box-shadow(1px,1px,1px,0, #fff, true); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;1,400&display=swap");
:root {
  --main-bg-color: $main_bg_color;
  --main-text-color: $main_text_color ;
}

@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@-webkit-keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@-moz-keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@-webkit-keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@-moz-keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@-moz-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@use "variables" as *;
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Titillium Web", sans-serif;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow-x: hidden;
  background-color: #ffffff;
  color: #687179;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #141414;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

a {
  color: #C5212F;
}

a,
.btn,
button {
  -webkit-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  outline: medium none;
}

p {
  color: #666666;
  margin: 0 0 15px;
  text-transform: none;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif, sans-serif;
}

a {
  -webkit-transition-duration: 0.3s;
     -moz-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
     -moz-transition-timing-function: ease-in-out;
       -o-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-transition-delay: 0s;
     -moz-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  transition-property: background-color, color;
  text-decoration: none;
  font-weight: 600;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

html {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
     -moz-box-sizing: inherit;
          box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 820px) {
  .container {
    max-width: 800px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1350px) {
  .container {
    max-width: 1300px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 45px;
  padding-left: 45px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 820px) {
  .container {
    max-width: 800px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1350px) {
  .container {
    max-width: 1300px;
  }
}

.row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
          flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.333333%;
     -moz-box-flex: 0;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
     -moz-box-flex: 0;
          flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.666667%;
     -moz-box-flex: 0;
          flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.333333%;
     -moz-box-flex: 0;
          flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.666667%;
     -moz-box-flex: 0;
          flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.333333%;
     -moz-box-flex: 0;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.666667%;
     -moz-box-flex: 0;
          flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.333333%;
     -moz-box-flex: 0;
          flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.666667%;
     -moz-box-flex: 0;
          flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
     -moz-box-flex: 0;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.333333%;
     -moz-box-flex: 0;
          flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.666667%;
     -moz-box-flex: 0;
          flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
     -moz-box-ordinal-group: 0;
          order: -1;
}

.order-last {
  -ms-flex-order: 13;
  -webkit-box-ordinal-group: 14;
  -webkit-order: 13;
     -moz-box-ordinal-group: 14;
          order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
     -moz-box-ordinal-group: 1;
          order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
     -moz-box-ordinal-group: 2;
          order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
     -moz-box-ordinal-group: 3;
          order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
     -moz-box-ordinal-group: 4;
          order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
     -moz-box-ordinal-group: 5;
          order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
     -moz-box-ordinal-group: 6;
          order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
     -moz-box-ordinal-group: 7;
          order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
     -moz-box-ordinal-group: 8;
          order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
     -moz-box-ordinal-group: 9;
          order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
     -moz-box-ordinal-group: 10;
          order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
     -moz-box-ordinal-group: 11;
          order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
     -moz-box-ordinal-group: 12;
          order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
     -moz-box-ordinal-group: 13;
          order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
       -moz-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
       -moz-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
       -moz-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
       -moz-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
       -moz-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
       -moz-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 769px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
       -moz-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
       -moz-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
       -moz-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
       -moz-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
       -moz-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
       -moz-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
       -moz-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
       -moz-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
       -moz-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
       -moz-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
       -moz-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
       -moz-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1290px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
       -moz-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
       -moz-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
       -moz-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
       -moz-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
       -moz-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
       -moz-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1500px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xxl-4 > * {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    -ms-flex: 0 0 20%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
       -moz-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
       -moz-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
       -moz-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
       -moz-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
       -moz-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
       -moz-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
       -moz-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
       -moz-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1;
  }
  .order-xxl-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13;
  }
  .order-xxl-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0;
  }
  .order-xxl-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1;
  }
  .order-xxl-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2;
  }
  .order-xxl-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3;
  }
  .order-xxl-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4;
  }
  .order-xxl-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5;
  }
  .order-xxl-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6;
  }
  .order-xxl-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7;
  }
  .order-xxl-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8;
  }
  .order-xxl-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9;
  }
  .order-xxl-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10;
  }
  .order-xxl-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11;
  }
  .order-xxl-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}

body {
  font-size: 14px;
  line-height: 20px;
}

.olb_orange_button {
  background-color: #E65B00;
  color: #fff;
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  padding: 20px 35px;
}

@media screen and (max-width: 768px) {
  body h1 {
    font-size: 28px;
  }
  body h2 {
    font-size: 24px;
  }
  body h3 {
    font-size: 21px;
  }
  body h4 {
    font-size: 18px;
  }
  body h5, body h6, body p {
    font-size: 16px;
  }
}
